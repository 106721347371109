let isLoaded = false
const emptyEntry = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
}
let entry = emptyEntry

const userEl = document.getElementById("user")
if (userEl) {
  entry = JSON.parse(userEl.textContent)
  const { email, phone, first_name, last_name } = entry
  if (email !== "" && first_name !== "" && last_name !== "" && phone !== "") {
    isLoaded = true
  }
}

const initialState = {
  entry,
  isLoaded,
  isLoading: false,
  error: null,
}

export default function profile(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case "FETCH_PROFILE_START":
      return { ...state, isLoading: true, error: null }
    case "FETCH_PROFILE_SUCCESS":
      return { ...state, isLoading: false, entry: payload, isLoaded: true }
    case "FETCH_PROFILE_ERROR":
      return { ...state, isLoading: false, error: payload }

    case "UPDATE_PROFILE_START":
      return { ...state, isLoading: true, error: null }
    case "UPDATE_PROFILE_SUCCESS":
      return { ...state, isLoading: false, entry: payload }
    case "UPDATE_PROFILE_ERROR":
      return { ...state, isLoading: false, error: payload }

    case "SEND_PIN_SUCCESS":
      return { ...state, entry: payload, isLoaded: true }

    case "SEND_SMS_START":
    case "LOGOUT_SUCCESS":
      return { ...initialState, entry: emptyEntry, isLoaded: false }

    default:
      return state
  }
}
