const initialState = {
  entries: [],
  isLoading: false,
  isLoaded: false,
  error: null,
}

export default function contracts(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case "FETCH_CONTRACTS_START":
      return { ...state, isLoading: true, error: null }
    case "FETCH_CONTRACTS_SUCCESS":
      return { ...state, isLoading: false, entries: payload, isLoaded: true }
    case "FETCH_CONTRACTS_ERROR":
      return { ...state, isLoading: false, error: payload }

    case "ADD_CONTRACT_START":
      return { ...state, isLoading: true, error: null }
    case "ADD_CONTRACT_SUCCESS":
      return { ...state, isLoading: false, entries: payload }
    case "ADD_CONTRACT_ERROR":
      return { ...state, isLoading: false, error: payload }

    case "REMOVE_CONTRACT_START":
      return { ...state, isLoading: true, error: null }
    case "REMOVE_CONTRACT_SUCCESS":
      return { ...state, isLoading: false, entries: payload }
    case "REMOVE_CONTRACT_ERROR":
      return { ...state, isLoading: false, error: payload }

    case "SEND_SMS_START":
    case "LOGOUT_SUCCESS":
      return initialState

    default:
      return state
  }
}
