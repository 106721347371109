import { readLocal, writeLocal, deleteLocal } from "store/utils"

const initialState = {
  authenticated: readLocal("authenticated") === "true" || false,
  smsSuccess: false,
  isLoading: false,
  error: null,
  storedIntent: !!readLocal("intent") ? JSON.parse(readLocal("intent")) : null,
}

export default function app(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case "SEND_SMS_START":
      writeLocal("authenticated", "false")
      return {
        ...state,
        isLoading: true,
        smsSuccess: false,
        authenticated: false,
        error: null,
      }
    case "SEND_SMS_SUCCESS":
      return { ...state, isLoading: false, smsSuccess: true }
    case "SEND_SMS_ERROR":
      writeLocal("authenticated", "false")
      return {
        ...state,
        isLoading: false,
        error: payload,
        authenticated: false,
      }

    case "SEND_PIN_START":
      return { ...state, isLoading: true, authenticated: false, error: null }
    case "SEND_PIN_SUCCESS":
      writeLocal("authenticated", "true")
      return { ...state, isLoading: false, authenticated: true }
    case "SEND_PIN_ERROR":
      writeLocal("authenticated", "false")
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        error: payload,
      }

    case "STORE_INTENT":
      writeLocal("intent", JSON.stringify(payload))
      return { ...state, storedIntent: payload }

    case "CLEAR_INTENT":
      deleteLocal("intent")
      return { ...state, storedIntent: null }

    case "LOGOUT_START":
      return { ...state, isLoading: true }

    case "LOGOUT_SUCCESS":
    case "CLEAR_AUTHENTICATED":
      writeLocal("authenticated", "false")
      return {
        ...state,
        smsSuccess: false,
        authenticated: false,
        isLoading: false,
      }

    default:
      return state
  }
}
