import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import { useMemo } from "react"

export function useActions(actions, deps) {
  const dispatch = useDispatch()
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch],
  )
}

export function readLocal(key) {
  if (!window.localStorage) return null

  return window.localStorage.getItem(key)
}
export function writeLocal(key, value) {
  if (!window.localStorage) return null

  return window.localStorage.setItem(key, value)
}
export function deleteLocal(key) {
  if (!window.localStorage) return null

  window.localStorage.removeItem(key)
}
