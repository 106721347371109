import { createBrowserHistory } from "history"
import { createStore, applyMiddleware } from "redux"
import { routerMiddleware } from "connected-react-router"

import { thunk } from "redux-thunk"

import createRootReducer from "./reducers"

export const historyObject = createBrowserHistory()

export default function configureAppStore(initialState = {}) {
  return createStore(
    createRootReducer(historyObject),
    initialState,
    applyMiddleware(thunk, routerMiddleware(historyObject)),
  )
}
