import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import appReducer from "./app/reducer"
import garageReducer from "./garage/reducer"
import contractsReducer from "./contracts/reducer"
import profileReducer from "./profile/reducer"
import searchReducer from "./search/reducer"

export function logger(state, action) {
  return action
}

export default function (history) {
  return combineReducers({
    app: appReducer,
    contracts: contractsReducer,
    garage: garageReducer,
    lastAction: logger,
    profile: profileReducer,
    router: connectRouter(history),
    search: searchReducer,
  })
}
