import { readLocal, writeLocal } from "store/utils"

function analyzeHTML(html) {
  // name
  const nameMatcher = /<\/i>(.+)<\/div>/
  const nameIndex = html.lastIndexOf("</i>")
  if (nameIndex < 0) return {}
  const nameStr = html.substring(nameIndex, html.length)
  const nameMatch = nameStr.match(nameMatcher)
  const make_and_model = nameMatch[1]
  // import status
  const sloFlagMatcher = /\/static\/img\/slo_flag/
  const sloFlagMatch = html.match(sloFlagMatcher)
  const imported = !sloFlagMatch[0]
  // prvi lastnik
  const first_owner = !html.includes("fa-users")
  // type
  const typeMatcher = /<i style="color: #3265A5; margin-right: 5px;" class="fas fa-(.+?)"/
  const typeMatch = html.match(typeMatcher)
  const vehicle_type = typeMatch[1]

  return { make_and_model, imported, first_owner, vehicle_type }
}

function readSearchEntries() {
  const results = []

  try {
    const entries = JSON.parse(readLocal("search-history"))
    if (entries && typeof entries === "object" && entries.length > 0) {
      entries.forEach((entry) => {
        if (!entry.vin) return false

        if (entry.html && typeof entry.html === "string") {
          entry = { ...entry, ...analyzeHTML(entry.html) }
          // entry.html = null
        }

        if (!entry.make_and_model || !entry.vin) return false

        results.push(entry)
      })
    }

    return results
  } catch (e) {
    console.log("Failed at reading search history", e)
  }
}

const initState = {
  query: "",
  entries: readSearchEntries() || [],
}

function searchReducer(state = initState, action) {
  const { type, payload } = action

  switch (type) {
    case "CHANGE_SEARCH":
      return { ...state, query: payload }

    case "ADD_TO_SEARCH":
      if (!state.entries.find((e) => e.vin === payload.vin)) {
        const newEntries = [payload, ...state.entries.slice(0, 6)]
        writeLocal("search-history", JSON.stringify(newEntries))
        return { ...state, entries: newEntries }
      } else {
        return state
      }
    default:
      return state
  }
}

export default searchReducer
